<!-- eslint-disable vue/no-unused-vars -->
<template>
  <b-card title="Detalhes" class="text-left">
    <!-- Tabela Principais -->
    <b-table :items="item" :fields="fields" stacked class="text-left">
      <template v-slot:cell(value)="{ item }">
        {{ numberToMonetary(item.value) }}
      </template>
    </b-table>

    <!-- Tabela Parcelas -->
    <b-table
      v-show="installments.length > 0"
      :items="installments"
      :fields="fieldsInstallments"
      class="text-left my-2"
    >
      <template v-slot:cell(transaction_date)="{ item }">
        {{ dateFormatter(item.transaction_date) }}
      </template>
      <template v-slot:cell(value)="{ item }">
        {{ numberToMonetary(item.value) }}
      </template>
    </b-table>

    <!-- Botoes -->
    <div class="justify-center">
      <BButton variant="outline" @click="$router.push('/lancamento-de-receita')"
        >Voltar</BButton
      >

      <BButton @click="$router.push(`/editar-receita/${$route.params.id}`)"
        >Editar</BButton
      >
    </div>
  </b-card>
</template>

<script>
import { BCard, BTable, BButton } from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTable,
    BButton,
  },
  data: () => ({
    fields: [
      {
        key: 'project_name',
        label: 'Projeto',
        sortable: false,
      },
      {
        key: 'status',
        label: 'Status',
        sortable: false,
      },
      {
        key: 'transaction_date',
        label: 'Data da transação',
        sortable: false,
      },
      {
        key: 'reference_date',
        label: 'Mês de referência',
        sortable: false,
      },
      {
        key: 'value',
        label: 'Valor total',
        sortable: false,
      },
      {
        key: 'installments',
        label: 'Parcelado',
        sortable: false,
      },
      {
        key: 'created_at',
        label: 'Criado em',
        sortable: false,
      },
      {
        key: 'updated_at',
        label: 'Ultima modificação',
        sortable: false,
      },
    ],
    fieldsInstallments: [
      {
        key: 'value',
        label: 'Valor da parcela',
        sortable: false,
      },
      {
        key: 'transaction_date',
        label: 'Data da transação',
        sortable: false,
      },
    ],
    haveInstallments: false,
    item: [],
    installments: [],
  }),

  created() {
    this.getItem();
  },

  computed: {},

  methods: {
    async getItem() {
      this.$store
        .dispatch('getRevenue', {
          id: this.$route.params.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          const item = {
            ...resp,
            reference_date: this.dateFormatter(resp.reference_date, 'MM/yyyy'),
            transaction_date: this.dateFormatter(
              resp.transaction_date,
              'dd/MM/yyyy'
            ),
            created_at: this.dateFormatter(resp.created_at),
            updated_at: this.dateFormatter(resp.updated_at),
            installments: resp.installments.length > 0 ? 'Sim' : 'Não',
          };
          this.item.push(item);
          this.haveInstallments = item.installments;

          this.installments = resp.installments;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.justify-center {
  display: flex;
  justify-content: center;
}
td::before {
  float: none !important;
}
</style>
